import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"

export default ({
  data: {
    page: {
      childMarkdownRemark: { frontmatter, html },
    },
  },
}) => {
  return (
    <Layout
      meta={{
        title: frontmatter.meta.title,
        description: frontmatter.meta.description,
      }}
    >
      <section>
        <div className="container">
          <div className="row">
            <div className="sub-page-heading">
              <h1>{frontmatter.title}</h1>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row">
            <div
              className="blog-text"
              dangerouslySetInnerHTML={{ __html: html }}
            />
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query PageQuery($name: String!) {
    page: file(
      relativeDirectory: { eq: "generic-pages" }
      name: { eq: $name }
    ) {
      childMarkdownRemark {
        html
        frontmatter {
          slug
          title
          meta {
            title
            description
          }
        }
      }
    }
  }
`
